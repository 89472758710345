<template>
  <div>
    <div v-if="active == 0">
      <div style="textAlign:center" v-show="show">
        <p>正在导入</p>
        <el-progress :percentage="100" :format="format" :indeterminate="true" />
      </div>
      <div class="theSteps">
        <el-steps :active="active" finish-status="success">
          <el-step title="上传导入数据" icon="el-icon-upload2" />
          <el-step title="数据预览" icon="el-icon-s-order" />
          <el-step title="数据导入完成" icon="el-icon-s-claim" />
        </el-steps>
      </div>
      <div class="myform">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          label-width="80px"
          :rules="rules"
        >
          <el-form-item label="选择品牌" prop="categoryName">
            <el-select
              v-model="value"
              placeholder="请选择品牌"
              @change="optionsChange"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.categoryName"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <p style="color:#D84146">*选择品牌之后再上传excel文件</p>
            <el-button
              type="text"
              icon="el-icon-download"
              @click="exportFu"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              >下载导入模板</el-button
            >
          </el-form-item>
          <el-form-item label="添加数据" prop="name">
            <input
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              suffix-icon="el-icon-link"
              @change="uploads"
            />
            <el-button
              type="text"
              v-show="content"
              icon="el-icon-download"
              @click="exportFu2"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              >下载失败列表</el-button
            >
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              :disabled="dis"
              >下一步</el-button
            >
            <el-button @click="resetForm('ruleForm')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- <lastUploadStock v-if="active == 2" /> -->
    </div>
  </div>
  <secondUploadStock
    v-if="active == 1"
    :tableArr="tableList"
    :fileContent="fileContent"
  />
</template>
<script>
import request from "@/common/js/request.js";
import api from "@/common/js/api.js";
import { mapActions } from "vuex";
import { export2Excel } from "@/common/js/excelFu.js"; // 根据实际文件路径进行引入
import secondUploadStock from "./secondUploadStock";
// import lastUploadStock from "./lastUploadStock";
export default {
  data() {
    return {
      active: 0,
      val: "",
      ruleForm: [],
      rules: {
        categoryName: [
          {
            required: true,
            message: "请选择品牌",
            trigger: "blur",
          },
        ],
        // name: [
        //   {
        //     required: true,
        //     message: "请上传后缀为.xlsx,.xls的文件",
        //     trigger: "blur",
        //   },
        // ],
      },
      dis: true,
      fileContent: {},
      content: "",
      tableData: [], // 即将导出的数组
      columns: [
        { title: "商品品牌", key: "equipmentName" },
        { title: "商品名称", key: "partName" },
        { title: "规格", key: "collectValue" },
        { title: "库存数量", key: "createTime" },
      ],
      tableList: [],
      value: "",
      options: [],
      show: "",
      // title代表 字段名  key就是当前字段
    };
  },
  components: {
    secondUploadStock,
    // lastUploadStock,
  },
  mounted() {
    this.getOptions();
  },
  methods: {
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    ...mapActions("stockModule", [
      "stockDownload", //库房模板导入
      "stockImportFile", //库房表格导入
      "optLidaTemp",
      "downloadExcel",
    ]),
    ...mapActions("purchaseMudule", [
      "listSkuCategoryForFirst", //顶级分类请求
      "listSkuCategoryForChildren", //获取子集分类
    ]),
    ...mapActions("mysaleMudule", [
      "quoteAgentList", //品牌代理列表
    ]),
    // getOptions() {
    //   this.quoteAgentList({}).then((res) => {
    //     let { data, code } = res.data;
    //     console.log(data, code);
    //     if (code === "0" && data.length) {
    //       //   console.log(data);
    //       this.options = data;
    //     }
    //   });
    // },
    getOptions() {
      this.listSkuCategoryForFirst({}).then((res) => {
        let { data, code } = res.data;
        // console.log(data.data, code);
        if (code === "0" && data.data.length) {
          //   console.log(data);
          this.options = data.data;
        }
      });
    },
    optionsChange(val) {
      // console.log(val);
      this.value = val;
    },
    exportFu() {
      this.stockDownload({}).then((res) => {
        // export2Excel(
        //   this.columns, // 在data定义，里面字段代表即将导出的excel的表头
        //   this.tableData, // 实际的数据， 数组格式
        //   res.data.data.data // 导出文件的文件名
        // );
        // this.downloadExcel({ url: res.data.data.data, fileName: '客户导入模板' })
        const a = document.createElement("a");
        a.style.display = "none";
        a.download = res.data.data.data;
        a.href = `${request.defaults.baseURL}/excel/${res.data.data.data}`;
        document.body.appendChild(a);
        a.click(); // 自动触发点击a标签的click事件
        document.body.removeChild(a);
      });
    },
    exportFu2() {
      const a = document.createElement("a");
      a.style.display = "none";
      a.download = this.fileContent.failFileName;
      a.href = `${request.defaults.baseURL}/excel/${this.fileContent.failFileName}`;
      document.body.appendChild(a);
      a.click(); // 自动触发点击a标签的click事件
      document.body.removeChild(a);
    },
    submitForm(formName) {
      this.next();
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     // alert("submit!");

      //   } else {
      //     // console.log("error submit!!");
      //     return false;
      //   }
      // });
    },
    resetForm(formName) {
      //   this.$refs[formName].resetFields();
      // this.$router.push({ path: "index" });
      if (window.history.length > 1) {
        this.$router.back();
      } else {
        this.$router.push("index");
      }
    },
    format(percentage) {
      percentage === 100 ? "Full" : `${percentage}%`;
    },
    uploads(e) {
      this.show = "true";
      // this.$message.error("请选择品牌再上传");
      // console.log(e);
      // 获取到当前文件对象
      let file = e.target.files[0];
      // 传递一个FormData对象即可
      let formData = new FormData();
      formData.append("file", file); // 'file'可变相当于input表单的name属性
      let arr = [];
      // console.log("file", file,"formData",formData);
      // 服务器只需按照正常的上传程序代码即可
      // 'http://192.168.0.89:8966/lidamall/stock/import/importFile'
      axios
        .post(
          request.defaults.baseURL +
            api.stockImportFile +
            "?brandId=" +
            this.value,
          formData,
          {
            xsrfHeaderName: "Authorization", // 请求头的名字自定义，用你们后台给你的就是
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: window.localStorage.getItem("token"), // 请求头的规则，用你们后台给你的就是
            },
          }
        )
        .then((res) => {
          if (res.data.code === "0") {
            this.tableList = res.data.data.data.data;
            this.fileContent = res.data.data.data;
            // console.log(this.tableList);
            // console.log(res.data.data.data.failQty);
            this.dis = false;
            if (res.data.data.data.failQty != "0") {
              this.content = "true";
            }
            this.show = "";
            let successNum = res.data.data.data.successQty;
            let failNum = res.data.data.data.failQty;
            let str =
              "上传成功" +
              successNum +
              "条，上传失败" +
              failNum +
              "条,请下载失败列表";
            this.$message.success(str);
            // this.isShowTips = true
          } else {
            this.$message.error("上传失败,请选择品牌、下载模板，重新上传");
          }
        })
        .catch((err) => {
          console.error(err);
          // this.$message.error('哎呀~上传失败,请稍后重试')
        });
    },
  },
};
</script>
<style scoped>
.theSteps {
  width: 700px;
  margin: 50px auto;
}
/* 进行中状态：圈线 */
.theSteps >>> .el-step__head.is-process {
  color: #409eff;
  border-color: #409eff;
}
/* 进行中状态：圈内 */
.theSteps >>> .el-step__head.is-process > .el-step__icon {
  background: #fff;
  color: #409eff;
}
/* 进行中状态：title（文字） */
.theSteps >>> .el-step__title.is-process {
  color: #409eff;
}

/* 完成状态：圈线 */
.theSteps >>> .el-step__head.is-success {
  color: #409eff;
  border-color: #409eff;
}
/* 完成状态：title（文字） */
.theSteps >>> .el-step__title.is-success {
  color: #409eff;
}
/* 完成状态：line
 * 描述：第一步完成，第二步进行时，之间的进度条有颜色
 */
/* .theSteps
  >>> .el-step__head.is-success
  > .el-step__line
  > .el-step__line-inner {
  width: 100% !important;
  border-width: 1px !important;
} */
.myform {
  margin: 5% 0;
  display: flex;
  justify-content: center;
  align-content: center;
}
#batchUpload {
  width: 60px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -30px;
  margin-top: -10px;
}
.demo-progress .el-progress--line {
  margin-bottom: 15px;
  width: 350px;
}
</style>
